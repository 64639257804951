$primary: #06665E;
$secondary: #1E67A5;
$accent: #1F4C80;

$ps-1: #214B4D;
$ps-2: #20778D;

$light: #fff;
$semi-light: #E9E9E9;
$light-dark: #9F9F9F;

$dark: #111;
$dark2: #333333;

$layout-bg: #F7F8FF;

$light-l3: #E4E8F4;
$light-gray: #b1b1b1;
$light-gray-l2: #757575;
$light-gray-l3: #5F5F5F;
