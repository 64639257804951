@import '../../../assets/styles/_variables.sass';
@import '../../../assets/styles/_mixin.sass';

.appointment-form {
    min-height: 100vh;
 
    display: grid;
    place-items: center;
     
    form {
        display: grid;
        grid-template-columns: minmax(25rem, 20%);
        margin-bottom: 2rem;

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    /* Firefox */
    input[type=number] {
    -moz-appearance: textfield;
    }

    h1 {
        text-align: center;
        font-weight: bold;
        margin-bottom: 3rem;
    }


    .ant-radio-inner {

        &:hover {
            border-color: $primary;
        }
    }

 

    .ant-radio-checked {
        .ant-radio-inner {

            border-color:$primary;
            &:hover {
                border-color:$primary;
            }
            &::after {
                background-color:$primary;
                &:hover {
                    background-color:$primary;
                }
            }
        }
    }   

    #form-login_email,
    .ant-input-password {
        height: 2.8rem;
        padding-left: 1.2rem;
    }

    .ant-form-item-explain  {
        font-size: .8rem;
        padding: .1rem 0;
    }

    button.btn-primary {
    
        font-family: 'Open Sans';
        font-size: .8rem;
        font-weight: 600;
        text-transform: uppercase;
    
        // @include fadeInBottom(1s)
            
    }
 

    }

}