@import '../../assets/styles/_variables.sass';

.users {
    grid-template-columns: 30% 1fr auto 10% 10%;
    grid-template-rows: 3.1rem 3.1rem auto;
 
    @media (max-width: 500px) {
        grid-template-columns: 1fr 2fr auto 1fr;    
        grid-template-rows: 4rem 3.2rem 3.1rem auto;
        grid-template-areas: 
        "title title title extract"
        "search search search search "
        "range-date range-date range-date ."
        "table table table table"
        ;
  
    }

    grid-template-areas: 
    "title title . . extract"
    "search . . range-date range-date"
    "table table table table table"
    ;

    .title, .sub-title,.export   {
        align-self: flex-end !important; 
    }

   .ant-picker,.search  {
        align-self: flex-start !important;
    }
   
 
    .title {
        grid-area: title;
    }

     .range-date {
        grid-area: range-date;
    }

    .sub-title {
        grid-area: sub-title;
     }

    .users-extract {
        grid-area: extract;
     }

    .search {
        align-self: flex-start !important;
        grid-area: search;
    }
    .table {
        grid-area: table; 
        margin-top: .4rem;
        
        td:nth-child(3) {
            text-transform: inherit;
        }
    }
}