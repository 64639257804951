@import '../../assets/styles/_variables.sass';

.dashboard {
    grid-template-rows: 3.1rem 170px repeat(2,31rem);    
    grid-template-columns: 30% 1fr auto 10%;
  
     @media (max-width: 500px) {

    grid-template-columns: 1fr 2fr auto 1fr;    
    grid-template-rows: 4rem 3.2rem auto  repeat(2,35rem) auto 35rem;

    grid-template-areas: 
    "title title title extract"
    ". range-date range-date range-date"
    "most-clicked most-clicked most-clicked most-clicked"
    "location location location location"
    "age age age age"
    "mcuc mcuc mcuc mcuc"
    "c-users c-users c-users c-users"
     ;
 

    .totals {
 
        section {
            border: inherit !important;
        }
        
        section:nth-child(1)  {
            border-right: 1px #EBEBEB solid !important;
            border-bottom: 1px #EBEBEB solid !important;
        }

        section:nth-child(2)  {
            border-bottom: 1px #EBEBEB solid !important;
           
        }
        section:nth-child(3)  {
            border-right: 1px #EBEBEB solid !important;
         }
      
    }

    .location {

        grid-template-areas: 
        'h4 h4'
        'action action'
        'graph graph' !important;
    
        .action {
            justify-self: flex-start !important;
        }
        
    }

    .mcuc {
         grid-template-columns: 1fr 42% !important;
    }

 

    }

    grid-template-areas: 
    "title title range-date extract"
    "most-clicked most-clicked most-clicked most-clicked "
    "location location  age age"
    "mcuc mcuc  c-users c-users"
     ;

     
    .title {
        grid-area: title;
    }

 
    .most-clicked, .location, .age, .mcuc, .c-users  {
        background-color: $light;
        border-radius: 20px;
        box-shadow: 0px 3px 6px #0000001A;
    }

    .location, .age,.mcuc, .c-users  {
        padding: 1.5rem 1.7rem;
    }

    .most-clicked {
        grid-area: most-clicked;
        padding: 1rem 2rem;
        margin-top: 1rem;
        h4 {
            font-size: 1rem;
            color: $dark2;
            font-weight: bold;
            margin-bottom: 1rem;
        }

    }
    
    .totals {
        grid-area: totals;
   
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        // grid-auto-rows: 120px;
  
        section:nth-child(1),
        section:nth-child(2),
        section:nth-child(3) {
            border-right: 1px #EBEBEB solid;
        }
     
        section {
                min-height: 5rem;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-flow: column wrap;

            .count {
                line-height: 2rem;
                font-weight: bold;
                color: $primary;
                font-size: 1rem;
             
            }

            .slug {
                font-size: 1.1rem;
                color: $dark2;
               
             }

        }
    }

    .range-date {
        grid-area: range-date;
    }

    .dashboard-extract {
        grid-area: extract;
    }

    .location {
        grid-area: location;
        position: relative;
        display: grid;
        grid-template-columns: repeat(2, auto);
        grid-template-areas: 
        'h4 action'
        'graph graph';
        grid-template-rows: 1fr auto;

        @media (max-width: 500px) {
            grid-template-columns: repeat(3, auto);
            grid-template-areas: 
            'h4 h4'
            'action action'
            'graph graph';
            grid-template-rows: 1fr 1fr auto;
             .action {
               label {
                font-size: .6rem !important;
            
               }
            }
        }

        .action {
            grid-area: action;
            justify-self: flex-end;
          
            .ant-radio-button-wrapper-checked {
                background-color: $primary;
                border:1px solid $primary;
                &:hover {
                    background-color: $primary;
                }
            }

            label {
                font-size: .8rem;
                &:first-child  {
                    border-color: $primary;
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px; 
                }

                &:last-child  {
                    border-color: $primary;
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px; 
                }
    
            }

            .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked)  {
                color: #B5B5BF;
                border: 1px solid #B5B5BF;
                &:hover {
                  color: $primary;
                }
            }

            .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
                background-color: transparent;
            }
        }

        

        h4 {
            grid-area: h4;
            font-size: 1rem;
            font-weight: bold;
        }

        .wrapped-chart {
            grid-area: graph;
         }
 

        .recharts-surface {
            height: 70%;
            overflow: inherit;
        }

        .recharts-wrapper {
            min-height:420px;
        }

        .recharts-yAxis text {
            font-size: .8rem;
            fill: #B5B5BF;
        }

        .recharts-xAxis .recharts-cartesian-axis-tick text {
            fill: #B5B5BF;
            font-size: .8rem;
        }


 
       
    }

    .age {
        grid-area: age;
        position: relative;

        h4 {
            font-size: 1rem;
            font-weight: bold;
        }

        .recharts-text {
            font-family: 'Hind';
            font-weight: 600;
            font-size: .9rem;
            fill: $dark2;
        }

        .recharts-surface {
            height: 90%;
        }
        
        .recharts-legend-wrapper {

            padding: 1rem;

            ul {
                display: flex;
                flex-flow: column wrap;
                justify-content: flex-start;
                align-items: flex-start;

                li {
                    padding: .2rem 0;
                }

                .recharts-legend-item-text {
                    color: $dark2 !important;
                    margin-left: .5rem;
                }
            }
         }

 
    }

    .mcuc {
        grid-area: mcuc;
 
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-template-rows: 2rem auto ;
        grid-gap: 1rem;       
        grid-template-areas: 'title title' 'chart status';

        position: relative;


        @media (max-width: 500px) {
            display: grid;
            grid-template-columns: auto;
            grid-template-rows: 2rem auto  auto;
            grid-template-areas: 'title title' 'chart chart' 'status status';

        }
        
       h4 {
            grid-area: title;
            font-size: 1rem;
            font-weight: bold;
        }

        .wrapped-chart {
            grid-area: chart;
        }
        
        .dashboard-partof-empty {
            position: absolute;
            top: 30%;
            left: 25%;
            transform: translate(-50%, -50%) 
        }


        .status {
            grid-area: status;
            
            h5 {
                font-size: .9rem;
                font-weight: bold;
                margin-bottom: 1rem;
            }

            .progress {
                padding: 0 2rem 0 0;

                .name {
                    color: $dark2;
                    margin-top: .4rem;
                    display: inline-block;
                }

                .ant-progress {
                    position: relative;
                    div {
                        padding-right: 0;
                    }

                    span {
                        position: absolute;
                        top: -1.2rem;
                        right: 0;
                        font-weight: 600;
                    }

                    .ant-progress-text {
                        color: #333;
                    }
                }
            }
           
            
        }

        .recharts-sector {
            stroke: #0C665E;
        }

        .highest-percentage {
            font-size: 1.5rem;
            font-weight: bold;

        }


        .recharts-legend-wrapper {

            padding: 1rem;
            margin-left: 1rem;

            ul {
                display: flex;
                flex-flow: column wrap;
                justify-content: flex-start;
                align-items: flex-start;
                

                li {
                    padding: .2rem 0;
                }

                .recharts-legend-item-text {
                    color: $dark2 !important;
                    margin-left: .5rem;
                }
            }
         }
    }

    .c-users {
        grid-area: c-users;
 
    
        h4 {
            grid-area: h4;
            font-size: 1rem;
            font-weight: bold;
        }

        .legends {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: .5rem 0;
            
            span:before {
                content: '';
                width: .8rem;
                height: .8rem;
                border-radius: 50%;
                display: inline-block;
                margin-right: .5rem;
            }

            span:first-child {
                color: #0E7C72;

                &:before {
                    background-color: #0E7C72;
                }
            }

            span:last-child {
                color: #E0007D;
                margin-left: .8rem;

                &:before {
                    background-color: #E0007D;
                }
            }

        }

    

        .recharts-surface {
            height: 100%;
        }

        .recharts-wrapper {
            min-height:420px;
        }

        .recharts-yAxis text {
            font-size: .7rem;
            fill: #B5B5BF;
        }

        .recharts-xAxis .recharts-cartesian-axis-tick text {
              font-size: .9rem;
         }

         .recharts-xAxis .recharts-cartesian-axis-tick:first-child text {
             fill: #0E7C72;
          }

         .recharts-xAxis .recharts-cartesian-axis-tick:last-child text {
             fill: #E0007D;
          }


    }

    .dashboard-partof-empty {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) 
    }

 
 
}
 