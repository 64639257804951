@import '../../../assets/styles/_variables.sass';

 
@import '../../../assets/styles/_variables.sass'; 

aside {
 
  overflow: auto;
  position: sticky;
  top: 0;
  height: 100vh;
  padding: 2rem 1rem 1rem;
  
  background: $light;
  display: grid;
  grid-template-rows: 7rem auto 10rem;
  grid-template-areas:  "logo" "menu" "user"; 

  .logo {
    grid-area: logo;
    background-image: url(../../../assets/images/logo.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width:  120px;
    height: 90px;
    margin: 0 auto;
  }

  .menu {
    grid-area: menu;
 
    background: transparent;
    border-color: transparent;
  
    a {
        font-family: 'Nunito', sans-serif;
        font-size: 1rem;
        font-weight: 600;
        color: $dark2;
    }

   
    a:hover,
    a:focus {
      color: $primary;
    }

    .ant-menu-item {
      padding-left: 2rem !important;
      border-radius: 5px;
      background: transparent;
      transition: inherit;
      display: flex;
      align-items: center;
      min-height: 2.7rem;
    }

    li.ant-menu-item-selected {
        background-color: $primary !important;
       a {
         color: $light;
        }
    }

    .ant-menu-item-selected::after {
      display: none;
    }
  }

  .user {
    grid-area: user;
    align-self: flex-end;
    justify-self: center;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    margin-bottom: 2rem;

    .user-menu {
        position: absolute;
        bottom: 2.8rem;
        right: 0;
        width: 75%;
        transition: all .3s ease-in-out;
        background-color: #f2f2f2 !important;
        border-radius: .2rem;
        color: $dark2;
        cursor: pointer;

        .ant-menu-item {
          font-family: 'Nunito', sans-serif;
          font-size: .9rem;

            &:hover,&:focus {
              color: $primary;
              font-weight: 600;
          }
        }

      
    }
    
    .dropdown-indicator{
      color: $light-dark
    }

    .name {
        padding-left: .5rem;
        font-size: .8rem;
        text-transform: capitalize;
        font-weight: bold;
        color: $dark2;

    }

    .dropdown-indicator {
        margin-top: 1px;
        font-size: 1.4rem;
        height: 2rem;
        opacity: .8;
    }
  }
}