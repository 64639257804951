 @import '../../../assets/styles/_variables.sass';

.drawer {

    font-family: 'Nunito', sans-serif;

    .ant-drawer-content-wrapper {
   
        .ant-drawer-content {
         
            padding-top: 2rem;

        }
    }

    .ant-menu-inline .ant-menu-item::after  {
        border: none;
    }

    .ant-menu-item  {
        a {
            color: $dark;
        }
        &:hover {

            a {
        
            color: $primary;
                
            }

        }
    }

    a {
        
        &:hover {
            color: $primary;
        }
    }

    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
        
        
       
      a {
        color: $primary;
        font-weight: bold;

        &:hover {
            color: $primary !important;
        }
      }
    }

    .ant-drawer-content-wrapper {
        
        height: 310px !important;
    }
   
  
      ul {
          border: none;
      }

      svg {
          font-size: 1rem;
      }

      .user {
         grid-area: user;
        align-self: flex-end;
        justify-self: center;
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;
        margin-left:1.5rem;
    
        .user-menu {
            background-color: #fafafa !important;

            position: absolute;
            bottom: 2.2rem;
            left: 0;
            width: 42%;
            transition: all .3s ease-in-out;
            background-color: $light;
            border-radius: .2rem;
            color: $light-gray-l2;
            cursor: pointer;
            .ant-menu-item {
                > div {
                    font-family: 'Nunito', sans-serif;
                    font-size: .9rem;
               
                    &:hover,&:focus {
                        color: $accent;
                        font-weight: 600;
                    }
                }
            }
    
          
        }
        .user-avatar {
          color: $light-gray-l2;
         }
    
        .name, .dropdown-indicator  {
            font-family: 'Nunito', sans-serif;
             color: $dark;
        }
    
        .name {
            padding-left: .5rem;
            font-size: .9rem;
            text-transform: capitalize;
            font-weight: bold;
        }
    
        .dropdown-indicator {
          
            margin-top: 1px;
            font-size: 1.4rem;
            height: 2rem;
            opacity: .8;
       
        
        }
    
      }
  }