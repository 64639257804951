@import '../../assets/styles/_variables.sass';
@import '../../assets/styles/_mixin.sass';

.login {
    min-height: 100vh;
 
    display: grid;
    place-items: center;
     
    form {
        display: grid;
        grid-template-columns: minmax(20rem, 17%);
        margin-bottom: 2rem;

    .logo {
        background: url('../../assets/images/logo-2.png') no-repeat center / contain;
        width: 12rem;
        height: 12rem;
        margin: 0 auto  2.5rem;
        @include fadeInBottom(.7s);
    }

    #form-login_email {
        @include fadeInBottom(.8s)
    }

    .ant-input-password {
        @include fadeInBottom(.9s)
    }

    #form-login_email,
    .ant-input-password {
        height: 2.8rem;
        padding-left: 1.2rem;
    }

    .ant-form-item-explain  {
        font-size: .8rem;
        padding: .1rem 0;
    }

    button.btn-primary {
    
        font-family: 'Open Sans';
        font-size: .8rem;
        font-weight: 600;

        height: 2.7rem;
        margin: .2rem 0 .8rem;

        text-transform: uppercase;
    
        @include fadeInBottom(1s)
            
    }

    a {
        @include fadeInBottom(2s);
        color: $primary;

        &:hover {
            text-decoration: underline;
        }
    }

    }

}