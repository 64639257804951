@mixin fadeIn() {
    animation: fadeIn 1s ease-in;
    @keyframes fadeIn {
        from {
            opacity: 0; }
        to {
            opacity: 1; } } }

@mixin fadeInBottom($seconds) {
    animation: fadeInBottom $seconds ease-in;
    @keyframes fadeInBottom {
        0% {
            transform: translateY(50px);
            opacity: 0; }

        100% {
            transform: translateY(0);
            opacity: 1; } } }
