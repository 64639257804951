@import url('https://fonts.googleapis.com/css2?family=Hind:wght@300;400;600;700;800&family=Nunito:wght@300;400;600;700;800&family=Open+Sans:wght@300;400;600;700;800&display=swap');
@import './_variables.sass';
@import './_mixin.sass';

body {
  margin: 0;
  font-family: "Hind";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.btn-primary,.ant-btn:disabled {
  
  color: $light;
  background-color: $primary;
  border-color: $primary;
  
  &:hover,
  &:focus {
      opacity: .9;
      color: $light;
      background-color: $primary;
      border-color: $primary;
  }
  &:disabled {
    opacity: 0.7;
  }
}
 