@import '../assets/styles/_variables.sass';
@import '../assets/styles/_mixin.sass';
 
.layout {
    
  background-color: $layout-bg;
  min-height: 100vh;

  display: grid;
  grid-template-columns: minmax(260px, 17%) auto;
  grid-template-rows: auto 4rem;
  grid-template-areas:  'aside main';

  aside {
    grid-area: aside;
  }

  main {
    grid-area: main;
  }


  main {
      display: grid;
      grid-template-columns: 2rem auto 2rem;
      grid-template-rows: 3rem auto;
      grid-template-areas: 
      '. . .'
      '. page .';

  > section {

    grid-area: page;
    
    @include fadeIn();

    display: grid;
    grid-gap: 1rem;

    .title, .sub-title,.export, .ant-picker,.search  {
      align-self: flex-end;
    }

    .title {
        font-size: 1.5rem;
        font-weight: bold;
        margin: 0;
        color: $dark;

        display: flex;
        flex-wrap: wrap;

        span:not(.sub-title) {
          padding-right: 1rem;
        }
      
    }

    .title, .sub-title  {
   
      align-self: flex-end;
      color: $dark2;
      line-height: 1rem;
      
    }

    .sub-title  {
      font-weight: normal !important;
      font-size: 1rem;
    
    }

  .export {
     padding: 0;

   
    span {
      width: 100%;
     height: 100%;
     display: flex;
     align-items: center;
     justify-content:center;

     .extract-excelfile-element {
      
      display: flex;
      align-items: center;
      justify-content: space-between;
   
    
      svg {
          margin-right: .5rem;
          margin-bottom: .3rem;
          color: #fff;
      }
    
    }
   }
  }

    .ant-picker {
      font-family: 'Nunito';
        max-height: 40px;
        min-height: 40px;
        border: transparent;
        border-radius: 5px;

      
        .ant-picker-range-separator, .ant-picker-suffix {
            color: #d1d1d1;
        }

        .ant-picker-range-separator {
            font-size: 1rem;
        }

        .ant-picker-suffix {
            font-size: 1.2rem;
        }


        input {
            font-size: .9rem;
            text-align: center;

            &::placeholder {
                font-weight: bold;
                font-size: .9rem;
                color: $primary;
               
              }
        }

      }

  
    .search {
      height: 100%;
      border-radius: 30px;
      max-height: 40px;

      border: none;
      box-shadow: 0px 3px 6px #0000001A;
      border-radius: 25px;
      
      .ant-input-prefix {
        padding: 0 .5rem .2rem .5rem;
        color: #B5B5BF;
      }

      // .ant-input-group-addon {
      //     border-top-right-radius: 50%;
      //     border-bottom-right-radius: 50%;
      // }
  }

    .ant-select-lg  {
      width: 100%;
 
 
      .ant-select-selection-placeholder {
        font-weight: bold;
        font-size: .9rem;
        color: $accent;
      }
      .ant-select-selector {
        border: 1px solid $light-l3;
        border-radius: 5px;
     
      }

      .ant-select-arrow {
        font-size: 1rem;
        top: 40%;
        svg {
          color: $accent;
          font-size: .8rem;
          font-weight: bold;
          margin-bottom: 10px;
        }
      }
    
    }

      .ant-table {
        box-shadow: 0px 3px 6px #0000001A;
        border-radius: 20px;
        font-size: .9rem;

        .ant-table-column-sorter-up ,
        .ant-table-column-sorter-down {
        font-size: .6rem;
        }

        .ant-table-column-sorters {
         flex: inherit;
         justify-content: flex-start;

         span {
           flex: inherit;
           
         }

         .ant-table-column-sorter {
          padding: 0 .8rem;
         }
      }
       

        .ant-table-column-sorter-up.active,
        .ant-table-column-sorter-down.active {
          color: $primary !important;
        }
       
        thead  {
           height: 3.7rem !important;
           padding: 1rem !important;
        
        }
          th  {
             font-size: .8rem;
            background-color: $light;
             color: #878795;
            //  font-weight: 600; 

             &:first-child  {
              border-top-left-radius: 20px !important;
              }

               &:last-child {
               border-top-right-radius: 20px !important;
             }
          }

          td  {
            text-transform: capitalize;
            color: #333;
           
          }

          th:first-child,td:first-child {
          padding-left: 2rem;
          }
          th:last-child,td:last-child {
            padding-right: 2rem;
          }

          tbody {
            tr:last-child {
              td:first-child {
                 border-bottom-left-radius: 20px !important; 
              }
              td:last-child {
                 border-bottom-right-radius: 20px !important; 
              }
            }
          }

          th:not(.ant-table-column-has-sorters) {
            padding-top: 1.8rem;
          }

          th.ant-table-column-has-sorters {
            padding-top: .8rem;
          }

          .anticon-eye{
            cursor: pointer;

            svg {
              color: $light-gray;

              &:hover {
                color: $accent;
              }
            }
          }
      }
  }
  }
 
  
  @media (max-width: 576px  ) {
     
    & {
     
      padding: 0;

      grid-template-columns:  auto;
      grid-template-rows: auto 10rem;
       grid-template-areas:
      'main'
      'footer'
      ;

      aside {
        display: none;
      }

      main {
        grid-template-columns: auto;
        grid-template-rows: 4rem repeat(2,3rem) auto;
        grid-template-areas: 
        'header header header'
        '. page .';

        grid-gap: 1rem;

       header {
     
          grid-area: header;
          padding: 0 2rem;
          display: flex;
          justify-content: space-between;
          align-items: center;

          background-color: $light;

          .logo {
            background-image: url('../assets/images/logo.png');
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            width:  50px;
            height: 50px;
            
          }
      
           svg {
             font-size: 1.5rem;
             cursor: pointer;
           }
        }
      }
    }


    .title {
      font-size: 1.3rem !important;
     padding-bottom: 1rem;
 
    span {
      line-height: 1rem;
    }
 
  }
  

  .export {
    font-size: .8rem !important;
}

  }
 

}



.ant-picker-active-bar {
  background-color: $primary !important;
}

.ant-picker-dropdown {
  .ant-picker-panel-container {
  font-family: 'Nunito', sans-serif;
  font-size: .8rem;

    .ant-picker-cell-selected {
    .ant-picker-cell-inner {
    background-color: $primary;
    }
    }

    .ant-picker-cell-today {
    .ant-picker-cell-inner::before {
    border-color: $primary;
    }
    }

    .ant-picker-today-btn {
    color: $primary;
    &:hover {
    opacity: 0.6;
    }
    }

  }
}

.ant-picker-focused {
  box-shadow: none !important;
}

.ant-pagination {

  
  li {
      margin-right: .7px !important;
      border: none !important;
      box-shadow: 0px 5px 12px #E1E8F266 !important; 
      font-family: 'Nunito', sans-serif !important;
      padding: 0 .8rem;
      min-height: 2.2rem;
      background-color: #fff;
      display: flex;
      align-items: center;
    button,a {
      border: none !important;
      font-weight: bold;
      margin: 0 !important;
    }
  }

  .ant-pagination-prev {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    button {
 
       svg {
         font-weight: bold;
       }
    }
  }

  .ant-pagination-next {
    border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
  }

  .ant-pagination-disabled {
    margin-right: .9px !important;
  }

  li:not(.ant-pagination-disabled)  {
    &:hover {
       button,a {
        color: $primary !important;
      }
     }
  }

  li:not(.ant-pagination-item-active) {
    button,a  {
      color: #757575 !important;
    }
  }

  .ant-pagination-item-active {
   a {
    color: $primary !important;
   }
  }

  li.ant-pagination-disabled {
    opacity: 0.6;
  }
}

.ant-btn.ant-btn-lg  {
  font-size: .9rem;
  border-color: $primary;
  background-color: $primary;
  color: $light;
  border-radius: 4px;
  &:hover,&:focus {
    border-color: $primary;
    background-color: $primary;
    color: $light;
  }
}


.ant-pagination-options-size-changer {
  .ant-select-selector {
    border: none !important;
  }
}

.ant-pagination-options-size-changer.ant-select-focused {
  .ant-select-selector {
    box-shadow: none !important;
  }
}

.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  display: none;
}